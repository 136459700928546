<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>
<script>
import { registerPlugin } from '@capacitor/core'
import { LocalNotifications } from '@capacitor/local-notifications'
import moment from 'moment'
import { PushNotifications } from '@capacitor/push-notifications'
import { getUserData } from '@/auth/utils'

const BackgroundGeolocation = registerPlugin('BackgroundGeolocation')
const LayoutHorizontal = () => import('@/layouts/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/LayoutFull.vue')

export default {
  components: {
    LayoutFull,
    LayoutHorizontal,
  },
  data() {
    return {
      userData: getUserData(),
      interval: null,
      mercureToken: null,
      eventSource: null,
      eventSourceOrder: null,
      mercureInterval: null,
    }
  },
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return 'layout-horizontal'
    },
  },
  created() {
    PushNotifications.addListener(
      'pushNotificationReceived',
      notification => {
        LocalNotifications.schedule({
          notifications: [
            {
              id: new Date().getTime(),
              title: notification.title,
              body: notification.body,
              sound: 'default',
              schedule: {
                allowWhileIdle: true,
              },
            },
          ],
        })
      },
    )
    const self = this
    BackgroundGeolocation.addWatcher(
      {
        // If the "backgroundMessage" option is defined, the watcher will
        // provide location updates whether the app is in the background or the
        // foreground. If it is not defined, location updates are only
        // guaranteed in the foreground. This is true on both platforms.

        // On Android, a notification must be shown to continue receiving
        // location updates in the background. This option specifies the text of
        // that notification.
        backgroundMessage: 'Cancel to prevent battery drain.',

        // The title of the notification mentioned above. Defaults to "Using
        // your location".
        backgroundTitle: 'Using your location',

        // Whether permissions should be requested from the user automatically,
        // if they are not already granted. Defaults to "true".
        requestPermissions: true,

        // If "true", stale locations may be delivered while the device
        // obtains a GPS fix. You are responsible for checking the "time"
        // property. If "false", locations are guaranteed to be up to date.
        // Defaults to "false".
        stale: false,

        // The minimum number of metres between subsequent locations. Defaults
        // to 0.
        distanceFilter: 50,
      },
      function callback(location, error) {
        if (error) {
          if (error.code === 'NOT_AUTHORIZED') {
            if (
              window.confirm(
                'This app needs your location, ' +
                  'but does not have permission.\n\n' +
                  'Open settings now?',
              )
            ) {
              // It can be useful to direct the user to their device's
              // settings when location permissions have been denied. The
              // plugin provides the 'openSettings' method to do exactly
              // this.
              BackgroundGeolocation.openSettings()
            }
          }
          return console.error(error)
        }

        if (
          self.$store.state.logged &&
          self.$store.state.onOff &&
          self.$store.state.car
        ) {
          
          self.$Cars.updateResourceByUrl({
            url: self.$store.state.car,
            body: {
              latitude: location.latitude.toString(),
              longitude: location.longitude.toString(),
            },
          })
        }
        return true
      },
    ).then(function afterTheWatcherHasBeenAdded(watcherId) {
      // When a watcher is no longer needed, it should be removed by calling
      // 'removeWatcher' with an object containing its ID.
      /*
      BackgroundGeolocation.removeWatcher({
        id: watcher_id,
      })
      */
     return watcherId
    })

    this.mercureInterval = setInterval(async () => {
      if (this.$store.state.logged) {
        this.$Mercure
          .getResourceByUrl({ url: '/mercure/login' })
          .then(response => {
            if (response.status === 200) {
              this.mercureToken = response.data.token

              clearInterval(this.mercureInterval)
              this.mercureInterval = 0

              const url = new URL(`https://${process.env.VUE_APP_MERCURE_URL}`)
              url.searchParams.append(
                'topic',
                `https://${process.env.VUE_APP_API_URL}/orders/{id}`,
              )

              url.searchParams.append(
                'topic',
                `https://${process.env.VUE_APP_API_URL}/adjust_ordering`,
              )

              if (!this.eventSource) {
                this.eventSource = this.$sse
                  .create({
                    url: url.href,
                    cleanup: true,
                    format: 'json',
                    withCredentials: false,
                    forcePolyfill: true,
                    polyfillOptions: {
                      headers: {
                        Authorization: `Bearer ${this.mercureToken}`,
                      },
                    },
                  })
                  .on('message', this.handleSSE)
                  .on('error', () => {})
                  .connect()
                  .catch(err =>
                    console.error('Failed make initial connection:', err),
                  )
              }
            }
          })
      }
    }, 500)

    LocalNotifications.requestPermissions()
  },
  destroyed() {
    clearInterval(this.interval)
    this.interval = 0
    this.eventSource = null
  },
  methods: {
    handleSSE(data) {
      if (data['@type'] === 'Order') {
        const order = JSON.parse(JSON.stringify(data))
        const op = order['@id'].split('/')
        const orderNumber = op[op.length - 1]
        const d = moment().format('DD.MM.YYYY, HH:mm')
        const userData = getUserData()

        if (
          order.courier.oldValue === userData['@id'] ||
          order.courier.newValue === userData['@id']
        ) {
          if (order.courier.oldValue === order.courier.newValue) {
            if (order.status.oldValue !== order.status.newValue) {
              // zmena stavu priradenej objednavky
              this.$store.dispatch('pushToFeed', {
                id: new Date().getTime(),
                date: d,
                title: 'Order Status Change',
                text: `Order #${orderNumber} changed status to ${this.$helper.getEnumTranslation(
                  'order_status',
                  order.status.newValue,
                )}.`,
              })
              LocalNotifications.schedule({
                notifications: [
                  {
                    id: new Date().getTime(),
                    title: 'Order Status Change',
                    body: `Order #${orderNumber} changed status to ${this.$helper.getEnumTranslation(
                      'order_status',
                      order.status.newValue,
                    )}.`,
                    schedule: {
                      allowWhileIdle: true,
                    },
                  },
                ],
              })
            }
          } else {
            // eslint-disable-next-line no-lonely-if
            if (order.courier.oldValue === userData['@id']) {
              // odobranie objednavky
              this.$store.dispatch('pushToFeed', {
                id: new Date().getTime(),
                date: d,
                title: 'Order Unassigned',
                text: `Order #${orderNumber} has been assigned to another courier.`,
              })

              LocalNotifications.schedule({
                notifications: [
                  {
                    id: new Date().getTime(),
                    title: 'Order Unassigned',
                    body: `Order #${orderNumber} has been assigned to another courier.`,
                    schedule: {
                      allowWhileIdle: true,
                    },
                  },
                ],
              })
            } else if (order.courier.newValue === userData['@id']) {
              // priradenie objednavky
              this.$store.dispatch('pushToFeed', {
                id: new Date().getTime(),
                date: d,
                title: 'Order Assigned',
                text: `Order #${orderNumber} has been assigned to you to be delivered. Please confirm you have been informed.`,
                orderID: order['@id'],
              })

              LocalNotifications.schedule({
                notifications: [
                  {
                    id: new Date().getTime(),
                    title: 'Order Assigned',
                    body: `Order #${orderNumber} has been assigned to you to be delivered. Please confirm you have been informed.`,
                    schedule: {
                      allowWhileIdle: true,
                    },
                  },
                ],
              })
            }
          }
        }
      }
      if (data['@type'] === 'AdjustOrdering') {
        const d = moment().format('DD.MM.YYYY, HH:mm')
        this.$store.dispatch('pushToFeed', {
          id: new Date().getTime(),
          date: d,
          title: 'Order Delivery Priority Changed',
          text: `Delivery priority of your Orders has changed, please check new order.`,
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/style.scss';
</style>