const mutations = {
    SET_COURIER_VISIBILITY(state, payload) {
        state.onOff = payload
    },
    SET_COURIER_LOG_STATE(state, payload) {
        state.logged = payload
    },
    SET_COURIER_CAR(state, payload) {
        state.car = payload
    },
    SET_FEED_CHANGE_FLAG(state, payload) {
        state.feedChangedFlag = payload
    },
    PUSH_TO_FEED(state, payload) {
        state.feed.unshift(payload)
    },
    REMOVE_FROM_FEED(state, payload) {
        const index = state.feed.findIndex(element => element.id === payload.id)
        state.feed.splice(index, 1)
    },
    UPDATE_FEED_MESSAGE(state, payload) {
        const index = state.feed.findIndex(element => element.id === payload.id)
        state.feed[index] = payload
    },
}

export default mutations
