const actions = {
    setCourierVisibility({ commit }, payload) {
        commit('SET_COURIER_VISIBILITY', payload)
    },
    setCourierLogState({ commit }, payload) {
        commit('SET_COURIER_LOG_STATE', payload)
    },
    setCourierCar({ commit }, payload) {
        commit('SET_COURIER_CAR', payload)
    },
    setFeedChangeFlag({ commit }, payload) {
        commit('SET_FEED_CHANGE_FLAG', payload)
    },
    pushToFeed({ commit }, payload) {
        commit('PUSH_TO_FEED', payload)
        commit('SET_FEED_CHANGE_FLAG', true)
    },
    removeFromFeed({ commit }, payload) {
        commit('REMOVE_FROM_FEED', payload)
    },
    updateFeedMessage({ commit }, payload) {
        commit('UPDATE_FEED_MESSAGE', payload)
    },
}

export default actions