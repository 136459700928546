
import Vue from 'vue'
import axios from 'axios'
import JwtService from './jwtService'

const axiosIns = axios.create({})
Vue.prototype.$http = axiosIns

const JWT = new JwtService(axiosIns)
export default JWT
