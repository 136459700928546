
import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueSSE from 'vue-sse'
import App from './App.vue'
import router from './router'
import store from './store'

import FactoryRepository from '@/api/FactoryRepository'
import helperFunctions from '@/libs/helper'
import '@/libs/toastification'
import JWT from '@/auth/JWT'
import '@/filters/filters'

// eslint-disable-next-line import/order
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

// SSE
Vue.use(VueSSE)

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

// Helper module
const helper = {
    install() {
        Vue.helper = helperFunctions
        Vue.prototype.$helper = helperFunctions
    },
}
Vue.use(helper)

// JWT
const Jwt = {
    install() {
        Vue.JWT = JWT
        Vue.prototype.$JWT = JWT
    },
}
Vue.use(Jwt)

// API Factory
FactoryRepository.init(JWT)
const api = {
    install() {
        Vue.AdjustInventories = FactoryRepository.get('AdjustInventories')
        Vue.prototype.$AdjustInventories = FactoryRepository.get('AdjustInventories')

        Vue.Cars = FactoryRepository.get('Cars')
        Vue.prototype.$Cars = FactoryRepository.get('Cars')

        Vue.CashRegistries = FactoryRepository.get('CashRegistries')
        Vue.prototype.$CashRegistries = FactoryRepository.get('CashRegistries')

        Vue.Categories = FactoryRepository.get('Categories')
        Vue.prototype.$Categories = FactoryRepository.get('Categories')

        Vue.Enums = FactoryRepository.get('Enums')
        Vue.prototype.$Enums = FactoryRepository.get('Enums')

        Vue.Files = FactoryRepository.get('Files')
        Vue.prototype.$Files = FactoryRepository.get('Files')

        Vue.Inventories = FactoryRepository.get('Inventories')
        Vue.prototype.$Inventories = FactoryRepository.get('Inventories')

        Vue.Mercure = FactoryRepository.get('Mercure')
        Vue.prototype.$Mercure = FactoryRepository.get('Mercure')

        Vue.Navigations = FactoryRepository.get('Navigations')
        Vue.prototype.$Navigations = FactoryRepository.get('Navigations')

        Vue.OptimalCarInventories = FactoryRepository.get('OptimalCarInventories')
        Vue.prototype.$OptimalCarInventories = FactoryRepository.get('OptimalCarInventories')

        Vue.Orders = FactoryRepository.get('Orders')
        Vue.prototype.$Orders = FactoryRepository.get('Orders')

        Vue.Products = FactoryRepository.get('Products')
        Vue.prototype.$Products = FactoryRepository.get('Products')

        Vue.ProductVariants = FactoryRepository.get('ProductVariants')
        Vue.prototype.$ProductVariants = FactoryRepository.get('ProductVariants')

        Vue.Users = FactoryRepository.get('Users')
        Vue.prototype.$Users = FactoryRepository.get('Users')
    },
}
Vue.use(api)

// Vue select use
Vue.component('v-select', vSelect)

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')