export default {
  loginEndpoint: `https://${process.env.VUE_APP_API_URL}/login`,
  registerEndpoint: `https://${process.env.VUE_APP_API_URL}/register`,
  refreshEndpoint: '/logout',
  logoutEndpoint: '/logout',

  tokenType: 'Bearer',

  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken'
}
