import Vue from 'vue'

const moment = require('moment')
require('moment/locale/sk')
Vue.use(require('vue-moment'), {
  moment,
})

Vue.filter('price', num => {
  if (num || num === 0) {
    return (`${parseFloat(num).toFixed(2)} €`).replace('.', ',')
  }
  if (num === 0) {
    return ('0 €')
  }
  return num
})

Vue.filter('%', num => {
  if (num) {
    return (`${parseFloat(num).toFixed(2)} %`).replace('.', ',')
  }
  if (num === 0) {
    return ('0 %')
  }
  return num
})

Vue.filter('power', num => {
  if (num) {
    return (`${parseFloat(num).toFixed(2)} kW`).replace('.', ',')
  }
  return num
})

Vue.filter('dec', num => {
  if (num) {
    return (parseFloat(num).toFixed(2)).replace('.', ',')
  }
  return num
})
