export default class AbstractResource {
    apiURL = null

    resource = null

    JWT = null

    constructor() {
      if (new.target === AbstractResource) {
        throw new TypeError('Abstract class "AbstractResource" cannot be instantiated directly.')
      }
    }
}
