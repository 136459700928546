import Resource from './Resource'

const apiURL = `https://${process.env.VUE_APP_API_URL}`
let repositories = {}

export default {
  init: JWT => {
    repositories = {
      AdjustInventories: new Resource(JWT, { apiURL, resource: 'adjust_inventories' }),
      Cars: new Resource(JWT, { apiURL, resource: 'cars' }),
      CashRegistries: new Resource(JWT, { apiURL, resource: 'cash_registries' }),
      Categories: new Resource(JWT, { apiURL, resource: 'categories' }),
      Enums: new Resource(JWT, { apiURL, resource: 'enums' }),
      Files: new Resource(JWT, { apiURL, resource: 'files' }),
      Inventories: new Resource(JWT, { apiURL, resource: 'inventories' }),
      Mercure: new Resource(JWT, { apiURL, resource: 'mercure' }),
      OptimalCarInventories: new Resource(JWT, { apiURL, resource: 'optimal_car_inventories' }),
      Orders: new Resource(JWT, { apiURL, resource: 'orders' }),
      Products: new Resource(JWT, { apiURL, resource: 'products' }),
      ProductVariants: new Resource(JWT, { apiURL, resource: 'product_variants' }),
      Users: new Resource(JWT, { apiURL, resource: 'users' }),
    }
  },
  get: name => repositories[name],
}
