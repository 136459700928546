import Vue from 'vue'
import VueRouter from 'vue-router'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'

Vue.use(VueRouter)

const routes = [
  // Logout
  {
    path: '/logout',
    name: 'logout',
    component: () => import('@/views/Logout.vue'),
    meta: {
      layout: 'full',
    },
  },
  // Logout End
  // Force Logout
  {
    path: '/force-logout',
    name: 'force-logout',
    component: () => import('@/views/ForceLogout.vue'),
    meta: {
      layout: 'full',
    },
  },
  // Force Logout End
  // Login 
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  // Login End
  // Forgot Password 
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('@/views/ForgotPassword.vue'),
    meta: {
      layout: 'full',
    },
  },
  // Forgot Password End
  // Home Start
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    meta: {
      layout: 'full',
    },
  },
  // Home End
  // Orders Start
  {
    path: '/orders',
    name: 'Orders',
    component: () => import('@/views/Courier/Orders/list.vue'),
    meta: {
      pageTitle: 'Orders',
      breadcrumb: [
        {
          text: 'Orders',
          active: true,
        },
      ],
    },
  },
  // Orders End
  // Cash register Start
  {
    path: '/cash-register',
    name: 'Cash register',
    component: () => import('@/views/Courier/CashRegister/index.vue'),
    meta: {
      pageTitle: 'Cash register',
      breadcrumb: [
        {
          text: 'Cash register',
          active: true,
        },
      ],
    },
  },
  // Cash register End
  // Stock Start
  {
    path: '/stock',
    name: 'Stock',
    component: () => import('@/views/Courier/Stock/index.vue'),
    meta: {
      pageTitle: 'Stock',
      breadcrumb: [
        {
          text: 'Stock',
          active: true,
        },
      ],
    },
  },
  {
    path: '/stock-control',
    name: 'Stock control',
    component: () => import('@/views/Courier/Stock/stockControl.vue'),
    meta: {
      pageTitle: 'Stock control',
      breadcrumb: [
        {
          text: 'Stock control',
          active: true,
        },
      ],
    },
  },
  // Stock End
  // Others Start
  {
    path: '/others',
    name: 'Others',
    component: () => import('@/views/Courier/Others/index.vue'),
    meta: {
      pageTitle: 'Others',
      breadcrumb: [
        {
          text: 'Others',
          active: true,
        },
      ],
    },
  }
  // Others End
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (!isLoggedIn && (to.name !== 'login' && to.name !== 'forgot-password' && to.name !== 'logout' && to.name !== 'force-logout' && to.path !== '/.well-known/apple-app-site-association' && to.path !== '/.well-known/assetlinks.json')) { 
    return next({ name: 'login' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  // Router guards
  if (isLoggedIn) {
    /* const userData = getUserData()
    // Admin
    if (userData.role === 'ROLE_ADMIN' && ([].includes(to.name))) {
      next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
    } */
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router